<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-title style="font-size: 1.5rem;">
        Images&nbsp;
        <span v-if="session">- {{ session.label }}</span>&nbsp;
        <va-icon :name="'fa4-edit'" style="padding-left: 1rem; cursor: pointer; color: #0000ee;" @click="editModal" v-if="access !== 'User' || (access === 'User' && sessionUser === user_id)" />
      </va-card-title>
      <va-card-content>
        <VaTabs v-model="tabIndex" class="tabs-row">
          <template #tabs>
            <VaTab
              v-for="tab in ['Images', '3D Images']"
              :key="tab"
            >
              {{ tab }}
            </VaTab>
          </template>
        </VaTabs>
        <Images
          v-if="session && sessionUser && tabIndex === 1"
          :session="session"
          :sessionUser="sessionUser"
        />
        <Images3D
          v-else-if="session && sessionUser && tabIndex === 2"
          :session="session"
          :sessionUser="sessionUser"
        />
      </va-card-content>
    </va-card>
    <va-modal
      v-model="editModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Edit a Session</h2>
      </template>
      <slot>
        <va-input
          class="mb-3"
          v-model="session.label"
          type="text"
          label="Label"
          style="margin-top: 1rem;"
        />
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="editModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="primary" @click="editModalConfirm">Save</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import Images from './Images'
import Images3D from './3DImages'

export default {
  components: { Images, Images3D },
  mounted: async function () {
    if (!this.loader) {
      this.loader = this.$loading.show()
    }
    this.filesObject = {}
    try {
      // Load session info
      this.loadSession()
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions/${this.session_id}/files` } })
    }
    if (this.loader) {
      this.loader.hide()
    }
  },
  watch: {
    tabIndex (newTabIndex) {
      localStorage.setItem('tab_index', newTabIndex)
    }
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      id: this.$route.params.id,
      user_id: this.$store.state.uid,
      access: this.$store.state.access,
      sessionUser: null,
      session_id: this.$route.params.sessionId,
      session: null,
      editModalShow: false,
      tabIndex: localStorage.getItem('tab_index') ? parseInt(localStorage.getItem('tab_index')) : 1,
    }
  },
  methods: {
    loadSession: async function (filtering = false) {
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Load session
        const session = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.session_id}`, { headers })
        this.session = session.data.session
        this.sessionUser = this.session.user_id
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions/${this.session_id}/files` } })
      }
    },
    editModal () {
      this.editModalShow = true
    },
    editModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const payload = {
          label: this.session.label
        }
        // Edit a session
        await axios.put(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.session.uid}`, payload, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'Session edited successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load session again
        const session = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.session.uid}`, { headers })
        this.session = session.data.session

        this.editModalShow = false
      } catch (error) {
        this.editModalShow = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions/${this.session_id}/files` } })
      }
      this.loader.hide()
    },
  },
}
</script>

<style>
.tabs-row {
  margin-bottom: 1rem;
}
</style>
